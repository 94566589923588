h1 {
  color:#004164;
  font-weight: 300;
  font-style: normal;
}
input {
  width:100%;
  height:100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  border:none;
}
button {
  border:none;
  padding:18px 22px;
  margin:10px 0;
  font-weight: 600;
}
#form {
  position: relative;
}
.overlay {
  position: absolute;
  background: rgba(255,255,255,.75);
  top: 0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
  z-index: 2;
}
.status {
  padding: 10px 0 10px;
  margin-bottom: 20px;
  color: #C01324;
  font-weight: bold;
  opacity: 0;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
}
.status__heading {
  margin:0;
}
.status:first-letter  {
  text-transform: capitalize;
}
.btn--primary {
  position: relative;
  background:#00a9e0;
  color: #fff;
}
.btn--secondary {
  color:#999;
  background:#fff;
  text-decoration: underline;
  cursor: pointer;
}
.btn-group {
  margin-top:25px;
  border-top:1px solid #ddd;
}
.container {
  max-width: 700px;
  margin:0 auto;
  padding:25px 50px 75px;
  background:#fff;
  box-shadow: 0 5px 15px rgba(1, 169, 225, 0.06), 0 5px 15px rgba(0, 0, 0, 0.08);
}
.container:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
@media screen and (min-width: 35.938em){
  .container {
    padding-left: 100px;
    padding-right: 100px;
  }
  .btn-group {
    margin-top:0;
    border-top:none;
    float:right;
  }
}
.col1 {
  padding-right:10px;
}
.col1, .col2 {
  width:50%;
  float:left;
  box-sizing: border-box;
}
.form-controls {
  height:34px;
  border:1px solid #ddd;
  border-radius:0;
  padding:5px;
  margin-bottom:10px;
  box-sizing: border-box;
  font-size:16px;
}
select.form-controls {
  -webkit-appearance: none;
  border-radius: 0;
  display:block;
  width:100%;
  background:#fff;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.form-controls:focus {
  outline: none;
  border-color: #00a9e0;
}
#token {
  margin-top:10px;
}
.disabled-bkg {
  background:#aaa;
}
#submit:disabled {
  cursor:not-allowed;
}
.payment-fields.disabled {
  cursor:not-allowed;
}
.firstdata-field-focused,
.focus {
  outline: none;
  border-color: #00a9e0;
}
.firstdata-field-invalid,
.invalid {
  color:#C01324;
  border-color: #C01324;
}
.firstdata-field-valid,
.valid {
  border-color:  #43B02A;
}
label.focus,
label.valid,
label.invalid {
  border:none;
}

.success {
  color: #43B02A;
}

.success-bkg {
  background: #43B02A;
}
.success-bkg .btn__loader:after {
  background: #43B02A;
}

.success .btn--secondary {
  padding: 0;
  margin: 30px 0 0;
}

/* https://github.com/lukehaas/css-loaders */

/* overlay */
.loader,
.loader:before,
.loader:after {
  background: #00a9e0;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #00a9e0;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;

}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


/* button loader */
.btn__loader {
  display:inline-block;
  font-size: 12px;
  margin-right:10px;
  text-indent: -9999em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.btn__loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.btn__loader:after {
  background: #00a9e0;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkbox-control {
  -webkit-appearance: checkbox;
  height: auto;
  width: auto;
}

.checkbox-div {
  margin-bottom: 10px;
}