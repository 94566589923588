.loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 6rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
    height: calc(30vh);
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
  }
  .loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem #0D3B39;
    animation-name: pulsIn;
  }
  .loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 #0D3B39;
  }

  @keyframes pulsIn {
    0% {
      box-shadow: inset 0 0 0 1rem #0D3B39;
      opacity: 1;
    }
    50%, 100% {
      box-shadow: inset 0 0 0 0 #FFC60B;
      opacity: 0;
    }
  }

  @keyframes pulsOut {
    0%, 50% {
      box-shadow: 0 0 0 0 #FFC60B;
      opacity: 0;
    }
    100% {
      box-shadow: 0 0 0 1rem #FFC60B;
      opacity: 1;
    }
  }
      