.registration__form{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
    justify-content: center;
}

.form__row{
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: auto;
}

.form__column{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
}

.form__section{
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 113px;
}

.form__section__header{
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}

.form__section__header > p.num{
    font-family: 'Axiforma Bold';
    font-size: 45px;
    line-height: 35px;
    color: #0D3B39;
    margin: 0;
}

.form__section__header > p.text{
    font-family: 'Axiforma Bold';
    font-size: 35px;
    line-height: 35px;
    color: #3E3E3E;
    margin: 0;
    margin-left: 24px;
}

.form__section__header > p.text.uppercase{
    text-transform: uppercase;
}

.form__text{
    font-family: 'Axiforma';
    font-size: 22px;
    line-height: 28px;
    margin: 0;
}

.form__text.bold{
    font-family: 'Axiforma Bold';
}

.accompanying__person_details{
    width: 90%;
    margin: 24px auto;
}

.price__item{
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    user-select: none;
    text-transform: uppercase;
}

.price__item > p {
    color: #0D3B39;
    font-family: 'Axiforma Bold';
    font-size: 26px;
    line-height: 28px;
    text-align: center;
}

.price__item > .price{
    border: 1px solid #8E8E8E;
    height: 50px;
    width: 390px;
}

.price__item.total > .price {
    background: rgba(13, 59, 57, 0.15);
}

.price__item > .price > p{
    font-size: 28px;
    line-height: 55px;
    text-align: center;
    margin: 0;
    font-family: 'Axiforma Bold';
}

.submit__form{
    display: block;
    background-color: #0D3B39;
    color: white;
    font-family: 'Axiforma Bold';
    font-size: 22px;
    line-height: 26px;
    width: 100%;
    max-width: 575px;
    height: 75px;

    margin: auto;
    margin-bottom: 108px;

    cursor: pointer;

    transition: all .3s ease;
    border: 2px solid transparent;
    text-transform: uppercase;
}

.submit__form:hover{
    background: white;
    border-color: #0D3B39;
    color: #0D3B39;
}


@media (max-width: 1024px) {
    .form__row{
        flex-wrap: wrap;
    }

    .form__column{
        margin-top: 24px;
    }

    .price__item{
        width: 100%;
    }

    .price__item > .price{
        width: 100%;
    }

}