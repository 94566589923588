.localeswitcher__wrapper{
    display: block;
    position: fixed;
    top: 24px;
    right: 24px;

    width: 70px;
    height: 35px;
    cursor: pointer;
    z-index: 1000;

    transition: all .3s ease;
}

.localeswitcher__wrapper:hover{
    transform: scale(1.1);
}

.localeswitcher__wrapper > img{
    width: 100%;
    height: auto;
}