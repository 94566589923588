body{
  margin: 0;
  padding: 0;
}

p{
  color: #0D3B39;
  font-style: normal;
}

.mb-24{
  margin-bottom: 24px !important;
}

.mb-12{
  margin-bottom: 12px !important;
}

.mb-104{
  margin-bottom: 104px !important;
}

.App{
  display: block;
}

.app__header{
  display: block;
  background: #0D3B39;
  border-bottom: 10px solid #FFC60B;
}

.app__header > img {
  display: block;
  padding-top: 107px;
  padding-bottom: 107px;
  margin: auto;
  max-width: 100%;
  max-height: 224px;
}

h1{
  font-family: 'Axiforma Black';
  font-size: 72px;
  display: block;
  text-align: center;
  position: relative;
  margin: 71px auto;
  color: #0D3B39;

  letter-spacing: 1px;
}

h2{
  font-family: 'Axiforma Black';
  margin: 24px auto;
  color: #0D3B39;
  text-align: center;
}

h2.mt{
  margin-top: 71px;
}

h2.mb{
  margin-bottom: 71px;
}

.app__footer{
  background: #303030;
  min-height: 425px;
}

.app__footer.no-height{
  height: 0;
}

.app__footer > p.orangeText{
  color: #FFC60B;
  text-align: center;
  font-family: 'Axiforma Bold';
  font-size: 35px;
  line-height: 110%; /* 38.5px */
  margin: 0;
  padding-top: 100px;
}

.app__footer > p.disclaimer__center{
  color: #fff;
  text-align: center;
  font-family: 'Axiforma';
  font-size: 26px;
  line-height: 110%; /* 38.5px */
  margin: 4px 0;
}

.app__footer > p.disclaimer__center a{
  color: #FFC60B;
}


.terms__container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  margin-bottom: 104px;
}

.terms_text{
  color: #0D3B39;
  font-family: 'Axiforma';
  font-weight: 400;
  font-size: 26px;
  line-height: 110%; /* 38.5px */
  margin: 0;
  margin-bottom: 24px;
}

.backButton{
  display: block;
  background-color: #FFC60B;
  color: #303030;
  font-family: 'Axiforma Bold';
  font-size: 22px;
  line-height: 26px;
  width: 100%;
  max-width: 275px;
  height: 75px;
  line-height: 75px;
  text-decoration: none;

  margin: 55px auto;

  cursor: pointer;

  transition: all .3s ease;
  border: 2px solid transparent;
  text-transform: uppercase;
  text-align: center;
}

.backButton:hover{
  background: #0D3B39;
  border-color: #FFC60B;
  color: #FFC60B;
}


@media (max-width: 1024px) {
  h1{
    font-size: 42px;
  }

  .form__text{
    font-size: 20px;
    line-height: 26px;
  }

}