
.input__wrap.text, .input__wrap.email{
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px 20px;
    width: 100%;
}

.input__wrap.text label, .input__wrap.email label{
    font-family: 'Axiforma Bold';
    color: #0D3B39;
}

.input__wrap.text input, .input__wrap.email input{
    display: block;
    height: 35px;
    padding: 0 14px;
    line-height: 50px;
    outline: none;
    border:  1px solid #8E8E8E;
    width: 100%;
    max-width: 395px;

    font-family: 'Axiforma';
    transition: all .3s ease;
}

.input__wrap.text input:focus, .input__wrap.email input:focus{
    background-color: rgba(13, 59, 57, 0.3);
}

.input__wrap.text.invalid > input, .input__wrap.email.invalid > input{
    background-color: rgba(255, 46, 46, 0.3);
}





.input__wrap.radio{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: auto;
    height: 40px;
    margin: 6px 0;
}

.input__wrap.radio, .input__wrap.radio > label {
    cursor: pointer;
}

.input__wrap.radio label > span{
    font-family: 'Axiforma';
    color: #0D3B39;
    font-weight: 500;
    font-size: 22px;
    margin-left: 8px;
}

.input__wrap.radio label > input{
    display: inline-block;
    height: 20px;
    width: 25px;
    outline: none;
    border:  1px solid #8E8E8E;

    font-family: 'Axiforma';
    transition: all .3s ease;

    margin: 0;
}


@media (max-width: 1024px) {
    .input__wrap.text input, .input__wrap.email input{
        width: auto;
        max-width: initial;
    }

    .input__wrap.radio {
        height: 80px;
        position: relative;
    }

    .input__wrap.radio > label > span{
        line-height: 25px;
        position: absolute;
        top: 0;
        left: 22px;
    }
}