@font-face {
    font-family:'Axiforma Thin';
    src: url('./fonts/Kastelov - Axiforma Thin.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family:'Axiforma Light';
    src: url('./fonts/Kastelov - Axiforma Light.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family:'Axiforma';
    src: url('./fonts/Kastelov - Axiforma Regular.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family:'Axiforma Medium';
    src: url('./fonts/Kastelov - Axiforma Medium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family:'Axiforma Bold';
    src: url('./fonts/Kastelov - Axiforma Bold.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family:'Axiforma Black';
    src: url('./fonts/Kastelov - Axiforma Black.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}